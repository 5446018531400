import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Insta from '../../assets/images/insta.svg';
import User from '../../assets/images/user.svg';
import Menu from '../../assets/images/menu.svg';
import Search from '../../assets/images/search.svg';
import Searchbar from '../Searchbar/Searchbar';
import Sidebar from '../Sidebar/Sidebar';
import './Navbar.css'; 
import Close from '../../assets/images/close.svg';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobileSearchOpen, setMobileSearchOpen] = useState(false);
  const { searchItem } = useParams(); 
  const [query, setQuery] = useState(searchItem || '');

  const searchBarRef = useRef(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleMobileSearchbar = () => {
    setMobileSearchOpen(!isMobileSearchOpen);
  };

  const toggleSearchbar = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  // Handle search action
  const handleSearch = () => {
    if (query.trim()) {
      navigate(`/allProducts/${encodeURIComponent(query.trim())}`);
    }
  };

  const handleClearSearch = (e) => {
    e.stopPropagation(); 
    setQuery('');
    setIsSearchOpen(false); 
    setMobileSearchOpen(false); 
    if (location.pathname.includes('/allProducts')) {
      navigate('/allProducts'); 
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.code === 'Enter' || e.keyCode === 13) {
      e.preventDefault(); 
      handleSearch();
    }
  };

  useEffect(() => {
    if (location.pathname.includes('/allProducts') && searchItem) {
      setQuery(searchItem); 
    }
  }, [location, searchItem]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target) &&
        !event.target.closest('.search-bar') 
      ) {
        setIsSearchOpen(false);
        setMobileSearchOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="navbar">
        <div className="navbar-left">
          <div style={{display: 'flex', alignItems:'center', gap: 14}} onClick={toggleSidebar}>
            <img src={Menu} alt="menu icon" />
            <span className="menu-label">Menu</span>
          </div>
          <div className="search-bar-toggle" onClick={toggleSearchbar}>
            <img src={Search} alt="search icon" />
            <span className="search-label">Search</span>
          </div>
          <div className="mobile-search-bar-toggle" onClick={toggleMobileSearchbar}>
            <img src={Search} alt="search icon" />
          </div>
        </div>
        <div className="navbar-center" style={{cursor:'pointer', letterSpacing:'1px'}} onClick={() => navigate('/')}>
          LE LORD
        </div>
        <div className="navbar-right">
          <a href="https://www.instagram.com/lelord.co" target="_blank" rel="noopener noreferrer">
            <img src={Insta} alt="instagram icon" className="icon" />
          </a>
          <img src={User} className="icon" alt="user icon" />
        </div>
      </div>
      <div ref={searchBarRef} className={`mobile-search-input-wrapper ${isMobileSearchOpen ? 'open' : ''}`}>
        <input 
          type="text" 
          value={query} 
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Search...."
        />
        {query && (
          <img
            src={Close} 
            alt="clear search"
            className="mobile-input-clear-icon"
            style={{position: 'absolute', right:'60px', top: '25px', pointer: 'cursor'}}
            onClick={handleClearSearch}
          />
        )}
      </div>
      <Searchbar isOpen={isSearchOpen} onClose={toggleSearchbar} />
      <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
    </>
  );
};

export default Navbar;
